const binding = { modules: {}, dataActions: {} };

    const registerActionId = (actionPath) => {
        if (binding.dataActions[actionPath] &&
            binding.dataActions[actionPath].default &&
            binding.dataActions[actionPath].default.prototype &&
            binding.dataActions[actionPath].default.prototype.id) {
            binding.dataActions[binding.dataActions[actionPath].default.prototype.id] = binding.dataActions[actionPath];
        } else {
            Object.keys(binding.dataActions[actionPath] || {}).forEach(exportName => {
                if (binding.dataActions[actionPath][exportName] &&
                    binding.dataActions[actionPath][exportName].prototype &&
                    binding.dataActions[actionPath][exportName].prototype.Action) {
                    binding.dataActions[binding.dataActions[actionPath][exportName].prototype.id] = binding.dataActions[actionPath][exportName];
                }
            })
        }
    };

    const registerSanitizedActionPath = (sanitizedActionPath, dataAction) => {
        if (process.env.NODE_ENV === 'development') {
            if (!dataAction.default) {
                throw new Error('Data action path does not have a default export');
            }
            if (!(dataAction.default.prototype.id && binding.dataActions[dataAction.default.prototype.id]) || !binding.dataActions[sanitizedActionPath]) {
                binding.dataActions[sanitizedActionPath] = dataAction;
            }
        } else {
            binding.dataActions[sanitizedActionPath] = dataAction;
            if (!binding.dataActions[sanitizedActionPath].default) {
                throw new Error('Data action path ' + sanitizedActionPath + ' does not have a default export');
            }
            binding.dataActions[sanitizedActionPath].default.prototype.RegistrationId = sanitizedActionPath;
            if (binding.dataActions[sanitizedActionPath].default.prototype.id) {
                binding.dataActions[binding.dataActions[sanitizedActionPath].default.prototype.id] = sanitizedActionPath;
            }
        }
    };
    

    (binding.modules['copper'] = {
        c: () => require('partner/themes/copper/copper.tsx'),
        $type: 'themeModule',
        da: [{name:'cart',  path:'@msdyn365-commerce/global-state/dist/lib/data-actions/get-cart-state-data-action', moduleName: 'checkout',runOn: 1}],
        definitionExtensions: ['active-image','b2b-requests-status','business-organization-list','buybox','cart-icon','cart','checkout','content-block','eve-navigation-menu','header','image-list','navigation-menu','order-template-list','promo-banner','quickview','ratings-histogram','reviews-list','search-result-container','search','sign-in','store-selector','tab','write-review'],
        iNM: false,
        ns: '__local__',
        n: 'copper',
        p: '__local__',
        
        pdp: '',
        
        themeSettings: 'copper.theme.settings.json',
        md: 'src/themes/copper'
    });
        

        {
            const sanitizedActionPath = '@msdyn365-commerce/global-state/dist/lib/data-actions/get-cart-state-data-action';
            let dataAction = require('@msdyn365-commerce/global-state/dist/lib/data-actions/get-cart-state-data-action');
            registerSanitizedActionPath(sanitizedActionPath, dataAction);
        }
            

        
    window.__bindings__ = window.__bindings__ || {};
    window.__bindings__.modules = {
        ...window.__bindings__.modules || {},
        ...binding.modules
    };
    
        window.__bindings__.dataActions = {
        ...window.__bindings__.dataActions || {},
        ...binding.dataActions
    };